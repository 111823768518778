import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// お知らせ
export type Info = {
  // ID
  id: number;
  // お知らせタイトル
  title: string;
  // お知らせ内容 (Markdown)
  text: string;
  // 公開期限
  unpublish_at: string;
  // 公開日時
  published_at: string;
  // 作成日時
  created_at: string;
  // 更新日時
  updated_at: string;
};

class DomainStrapiInfoState {
  // お知らせリスト
  infos: Info[] | undefined = undefined;
}

class DomainStrapiInfoGetters extends Getters<DomainStrapiInfoState> {}

class DomainStrapiInfoMutations extends Mutations<DomainStrapiInfoState> {
  clearInfos() {
    this.state.infos = undefined;
  }

  setInfos(payload: { infos: Info[] }) {
    this.state.infos = payload.infos;
  }
}

class DomainStrapiInfoActions extends Actions<
  DomainStrapiInfoState,
  DomainStrapiInfoGetters,
  DomainStrapiInfoMutations,
  DomainStrapiInfoActions
> {
  // お知らせリストをクリアする
  clearInfos() {
    this.mutations.clearInfos();
  }

  // お知らせリストを格納する
  setInfos(payload: { infos: Info[] }) {
    this.mutations.setInfos(payload);
  }
}

export const DomainStrapiInfo = new Module({
  actions: DomainStrapiInfoActions,
  getters: DomainStrapiInfoGetters,
  mutations: DomainStrapiInfoMutations,
  state: DomainStrapiInfoState,
});

export const DomainStrapiInfoMapper = createMapper(DomainStrapiInfo);
