








import Vue from 'vue';
import TheAlert from '@/components/generic/TheAlert.vue';
import TheLoadingOverlay from '@/components/generic/TheLoadingOverlay.vue';

export default Vue.extend({
  name: 'App',

  components: {
    TheAlert,
    TheLoadingOverlay,
  },
});
