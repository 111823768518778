











import Vue from 'vue';

export default Vue.extend({
  name: 'BaseButton',

  inheritAttrs: false,

  props: {
    // 無効化されているかどうか
    disabled: {
      type: Boolean,
    },
    // アウトラインボタンかどうか
    outlined: {
      type: Boolean,
      // VBtnのプロパティ名と合わせつつ、プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
  },

  methods: {
    // クリック時の処理
    onClick(event: Event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
  },
});
