import colors from 'vuetify/lib/util/colors';

// 基本設定ステータス
export const BASIC_STATUSES = [
  {
    color: 'warning',
    name: '無効',
    value: 0,
  },
  {
    color: 'info',
    name: '有効',
    value: 1,
  },
];

// 通話状況
export const DIAL_STATS = [
  {
    color: 'info',
    name: '正常応答',
    value: 'ANSWER',
  },
  {
    color: 'primary',
    name: 'キャンセル',
    value: 'CANCEL',
  },
  {
    color: 'success',
    name: '話中',
    value: 'BUSY',
  },
  {
    color: 'warning',
    name: '無応答',
    value: 'NOANSWER',
  },
  {
    color: 'error',
    name: '回線不可',
    value: 'CHANUNAVAIL',
  },
  {
    color: 'purple',
    colorHex: colors.purple.base,
    name: '回線輻輳',
    textColor: 'white',
    value: 'CONGESTION',
  },
];

// 事業者ステータス
export const PARTNER_STATUSES = [
  {
    accountEnabled: true,
    dialEnabled: true,
    name: '有効',
    value: 1,
  },
  {
    accountEnabled: true,
    dialEnabled: false,
    name: 'トライアル期間外',
    value: 2,
  },
  {
    accountEnabled: true,
    dialEnabled: false,
    name: '利用停止',
    value: 3,
  },
  {
    accountEnabled: false,
    dialEnabled: false,
    name: '強制ロック',
    value: 4,
  },
  {
    accountEnabled: false,
    dialEnabled: false,
    name: '強制解約',
    value: 8,
  },
  {
    accountEnabled: false,
    dialEnabled: false,
    name: '解約',
    value: 9,
  },
];

// トリガー
export const TRIGGERS = [
  {
    color: 'primary',
    name: 'Webhook',
    value: '',
  },
  {
    color: 'success',
    name: '手動発報',
    value: 'gui',
  },
];

// リクエスト処理進捗状況
export const INTERFACE_PROGRESSES = [
  {
    color: 'info',
    name: '正常終了',
    value: 'complete',
  },
  {
    color: 'error',
    name: 'エラー',
    value: 'error',
  },
];

// 通知状況
export const NOTIFY_STATUSES = [
  {
    color: 'info',
    name: 'エラーなし',
    value: true,
  },
  {
    color: 'error',
    name: 'エラーあり',
    value: false,
  },
];

// 通知処理進捗状況
export const NOTIFY_PROGRESSES = [
  {
    color: 'info',
    name: '正常終了',
    value: 'success',
  },
  {
    color: 'primary',
    name: 'リクエスト実施',
    value: 'request',
  },
  {
    color: 'error',
    name: 'エラー',
    value: 'error',
  },
];

// HTTPステータス
export const HTTP_STATUSES = [
  {
    color: 'info',
    value: 200,
  },
  {
    color: 'warning',
    value: 400,
  },
  {
    color: 'error',
    value: 401,
  },
  {
    color: 'primary',
    value: 403,
  },
  {
    color: 'amber lighten-1',
    colorHex: colors.amber.lighten1,
    value: 404,
  },
  {
    color: 'purple',
    colorHex: colors.purple.base,
    value: 500,
  },
];

// 切断者
export const HUP_USRS = [
  {
    color: 'primary',
    name: '発報先',
    value: 1,
  },
  {
    color: 'success',
    name: 'システム',
    value: 2,
  },
];

// 通話種別
export const CALL_TYPES = [
  {
    color: 'primary',
    name: '通常通話',
    value: 0,
  },
  {
    color: 'success',
    name: '輪番',
    value: 1,
  },
  {
    color: 'warning',
    name: '一斉通報',
    value: 2,
  },
  {
    color: 'info',
    name: '番号指定通知',
    value: 3,
  },
];

// フロー種別
export const FLOW_TYPES = [
  {
    name: '応答した時',
    value: 'answer',
  },
  {
    name: '通話終了時',
    value: 'answered',
  },
  {
    name: '話中の時',
    value: 'during',
  },
  {
    name: '無応答の時',
    value: 'noreply',
  },
  {
    name: '例外時',
    value: 'exception',
  },
];

// フローブロック種別
export const FLOW_BLOCK_TYPES = [
  {
    name: '音声再生',
    value: 'fms',
  },
  {
    name: '音声再生',
    value: 'fmd',
  },
  {
    name: 'ボタン分岐 (1桁)',
    value: 'dta',
  },
  {
    name: 'ボタン分岐 (複数)',
    value: 'dtl',
  },
  {
    name: 'Webhook',
    value: 'hpn',
  },
  {
    name: '切断',
    value: 'emp',
  },
];

// 更新完了メッセージ
export const MESSAGE_UPDATE_DONE = '更新しました。';

// 変更完了メッセージ
export const MESSAGE_CHANGE_DONE = '変更しました。';

// メール送信完了メッセージ
export const MESSAGE_SEND_EMAIL_DONE = 'メールを送信しました。';

// パスワード再設定完了メッセージ
export const MESSAGE_PASSWORD_RESET_DONE = 'パスワードを再設定しました。';

// APIキー再生成完了メッセージ
export const MESSAGE_GENERATE_API_KEY_DONE =
  '再生成しました。APIキーを記載したメールを代表メールアドレス宛に送信しました。';

// 手動発報受付完了メッセージ
export const MESSAGE_CALL_REQUEST_DONE =
  '発報処理を受け付けました。結果はしばらく待ってから通知履歴をご確認ください。';

// アカウント無効エラーメッセージ
export const MESSAGE_ACCOUNT_DISABLED = 'アカウントが無効です。';

// 通知フロー未選択エラーメッセージ
export const MESSAGE_FLOW_NOT_SELECTED = '通知フローが選択されていません。';

// パスワード入力ヒント
export const HINT_PASSWORD_COMPLEX =
  '半角英数字記号8文字以上50文字以下 (英小文字数字記号をそれぞれ含む) である必要があります。記号は=+-^$*.[]{}()?"!@#%&/\\,><\':;|_~`が使えます。';
