import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// 置き換えアイテム
type ReplaceItem = {
  // 一意キー
  key: string;
  // 置き換え文字列
  replacement: string;
  // 読み上げテキスト
  readingText: string;
};

class UIDialState {
  // 発報後、手動発報ダイアログを閉じるかどうか
  closeDialogAfterDial: {
    [key: string]: boolean;
  } = {};

  // 置き換えアイテム配列
  replaceItems: {
    [key: string]: ReplaceItem[];
  } = {};
}

class UIDialGetters extends Getters<UIDialState> {
  // 発報後、手動発報ダイアログを閉じるかどうか
  closeDialogAfterDial(partnerId: string): boolean {
    return this.state.closeDialogAfterDial[partnerId] || false;
  }

  // 置き換えアイテム
  replaceItems(partnerId: string, flowId: string): ReplaceItem[] {
    return _.cloneDeep(this.state.replaceItems[`${partnerId}_${flowId}`]) || [];
  }
}

class UIDialMutations extends Mutations<UIDialState> {
  setCloseDialogAfterDial(payload: { partnerId: string; close: boolean }) {
    this.state.closeDialogAfterDial[payload.partnerId] = payload.close;
  }

  setReplaceItems(payload: { partnerId: string; flowId: string; replaceItems: ReplaceItem[] }) {
    this.state.replaceItems[`${payload.partnerId}_${payload.flowId}`] = payload.replaceItems;
  }
}

class UIDialActions extends Actions<UIDialState, UIDialGetters, UIDialMutations, UIDialActions> {
  // 発報後閉じるチェックボックスの状態をセットする
  setCloseDialogAfterDial(payload: { partnerId: string; close: boolean }) {
    this.mutations.setCloseDialogAfterDial(payload);
  }

  // 置き換え文字列アイテムリストをセットする
  setReplaceItems(payload: { partnerId: string; flowId: string; replaceItems: ReplaceItem[] }) {
    this.mutations.setReplaceItems(payload);
  }
}

export type { ReplaceItem };

export const UIDial = new Module({
  actions: UIDialActions,
  getters: UIDialGetters,
  mutations: UIDialMutations,
  state: UIDialState,
});

export const UIDialMapper = createMapper(UIDial);
