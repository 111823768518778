import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  async getCallInfo() {
    try {
      const response = await Service.get(`/callinfo`, {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getCallInfo#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('getCallInfo#Error', error);

      throw error;
    }
  },

  async getCallerId() {
    try {
      const response = await Service.get(`/caller-id`, {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getCallerId#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('getCallerId#Error', error);

      throw error;
    }
  },

  async getPartner(params: { fields: string[] }) {
    try {
      const response = await Service.post(`/get-partner`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getPartner#Response', response);

      return response.data.result.record;
    } catch (error) {
      Log.error('getPartner#Error', error);

      throw error;
    }
  },

  async regenerateAPIKey() {
    try {
      const response = await Service.put(`/apikey`, JSON.stringify({}), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('regenerateAPIKey#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('regenerateAPIKey#Error', error);

      throw error;
    }
  },

  async updateAllowIPAddresses(params: { ipaddrs: string[] }) {
    try {
      const response = await Service.put(`/allow-ipaddr`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('updateAllowIPAddresses#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('updateAllowIPAddresses#Error', error);

      throw error;
    }
  },

  async updateSubMail(params: {
    contact_mails?: string[];
    failure_mails?: string[];
    billing_mails?: string[];
  }) {
    try {
      const response = await Service.put(`/submail`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('updateSubMail#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('updateSubMail#Error', error);

      throw error;
    }
  },
};
