import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  async getInvoice(invoiceId: string) {
    try {
      const response = await Service.get(`/stripe/invoice/${invoiceId}`, {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getInvoice#Response', response);

      return response.data.result.invoice;
    } catch (error) {
      Log.error('getInvoice#Error', error);

      throw error;
    }
  },

  async getInvoiceIds() {
    try {
      const response = await Service.get(`/stripe/invoices`, {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getInvoiceIds#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('getInvoiceIds#Error', error);

      throw error;
    }
  },

  async getInvoiceUpcoming() {
    try {
      const response = await Service.get(`/stripe/invoice-upcoming`, {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getInvoiceUpcoming#Response', response);

      return response.data.result.invoice;
    } catch (error) {
      Log.error('getInvoiceUpcoming#Error', error);

      throw error;
    }
  },
};
