import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  async getJoinLogs(params: {
    type: string;
    // 内容が不定のため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters?: { [key: string]: any }[];
    sources?: string[];
    sort?: { [key: string]: number };
    offset?: number;
    limit?: number;
    page?: number;
    // 内容が不定のため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aggs?: { [key: string]: any };
    join: {
      types: string[];
      sources: string[];
    };
  }) {
    try {
      const response = await Service.post(`/get-joinlogs`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getJoinLogs#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('getJoinLogs#Error', error);

      throw error;
    }
  },

  async getLogs(params: {
    type: string;
    // 内容が不定のため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters?: { [key: string]: any }[];
    sources?: string[];
    sort?: { [key: string]: number };
    offset?: number;
    limit?: number;
    page?: number;
    // 内容が不定のため除外
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aggs?: { [key: string]: any };
  }) {
    try {
      const response = await Service.post(`/get-logs`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getLogs#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('getLogs#Error', error);

      throw error;
    }
  },
};
