import 'github-markdown-css/github-markdown.css';
import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import ja from 'vuetify/src/locale/ja';
import '@/scss/styles.scss';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    current: 'ja',
    locales: { ja },
  },
  theme: {
    themes: {
      dark: {
        accent: '#e1a032',
        anchor: '#1976d2',
        primary: colors.brown.lighten1,
      },
      light: {
        accent: '#e1a032',
        anchor: '#1976d2',
        primary: colors.brown.lighten1,
      },
    },
  },
});
