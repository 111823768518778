
















import Vue from 'vue';

export default Vue.extend({
  name: 'BaseDialog',

  inheritAttrs: false,

  props: {
    // ダイアログの最大幅
    maxWidth: {
      type: [Number, String],
      default: 800,
    },

    // 外側クリック、escキーを無効にするか
    persistent: {
      type: Boolean,
    },

    // ダイアログタイトル
    title: {
      type: String,
      default: undefined,
    },

    // 表示中かどうか
    value: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    computedValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('update:value', value);
      },
    },
  },
});
