import dayjs from 'dayjs';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

class DomainPartnerState {
  // 登録日時
  registered: dayjs.Dayjs | undefined = undefined;

  // ステータス
  status: number | undefined = undefined;
}

class DomainPartnerGetters extends Getters<DomainPartnerState> {}

class DomainPartnerMutations extends Mutations<DomainPartnerState> {
  clearPartnerInfo() {
    this.state.registered = undefined;
    this.state.status = undefined;
  }

  setPartnerInfo(payload: { registered: dayjs.Dayjs; status: number }) {
    this.state.registered = payload.registered;
    this.state.status = payload.status;
  }
}

class DomainPartnerActions extends Actions<
  DomainPartnerState,
  DomainPartnerGetters,
  DomainPartnerMutations,
  DomainPartnerActions
> {
  // 事業者情報をクリアする
  clearPartnerInfo() {
    this.mutations.clearPartnerInfo();
  }

  // 事業者情報を設定する
  setPartnerInfo(payload: { registered: number; status: number }) {
    this.mutations.setPartnerInfo({
      registered: dayjs.unix(payload.registered),
      status: payload.status,
    });
  }
}

export const DomainPartner = new Module({
  actions: DomainPartnerActions,
  getters: DomainPartnerGetters,
  mutations: DomainPartnerMutations,
  state: DomainPartnerState,
});

export const DomainPartnerMapper = createMapper(DomainPartner);
