import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { createStore, Module } from 'vuex-smart-module';
import { DomainAuth } from '@/store/modules/domain/auth';
import { DomainPartner } from '@/store/modules/domain/partner';
import { DomainStrapiInfo } from '@/store/modules/domainStrapi/info';
import { UICommon } from '@/store/modules/ui/common';
import { UIDial } from '@/store/modules/ui/dial';
import { UIInfo } from '@/store/modules/ui/info';
import { UIPasswordReset } from '@/store/modules/ui/passwordReset';

Vue.use(Vuex);

const root = new Module({
  modules: {
    domain: new Module({
      modules: {
        auth: DomainAuth,
        partner: DomainPartner,
      },
    }),
    domainStrapi: new Module({
      modules: {
        info: DomainStrapiInfo,
      },
    }),
    ui: new Module({
      modules: {
        common: UICommon,
        dial: UIDial,
        info: UIInfo,
        passwordReset: UIPasswordReset,
      },
    }),
  },
});

const store = createStore(root, {
  plugins: [
    createPersistedState({
      key: 'crAuth',
      paths: ['domain.auth.token'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'crUserAttrs',
      paths: ['domain.auth.userAttributes'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'passwordResetMail',
      paths: ['ui.passwordReset.passwordResetMailAddress'],
      storage: sessionStorage,
    }),
    createPersistedState({
      key: 'closeAfterDial',
      paths: ['ui.dial.closeDialogAfterDial'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'replaceItems',
      paths: ['ui.dial.replaceItems'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'showedNavMenu',
      paths: ['ui.common.showedNavigationMenu'],
      storage: localStorage,
    }),
    createPersistedState({
      key: 'readInfoIds',
      paths: ['ui.info.readInfoIds'],
      storage: localStorage,
    }),
  ],
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
