import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  async dial(
    flowId: string,
    params: { phone: string; args: { [key: string]: string | number | boolean } }
  ) {
    try {
      const response = await Service.post(`/engine/dial/${flowId}`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('dial#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('dial#Error', error);

      throw error;
    }
  },
};
