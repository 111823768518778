















import Vue from 'vue';
import settings from '@/settings';
import { UICommonMapper } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'TheAlert',

  data(): {
    color: string | undefined;
    showed: boolean;
    text: string;
    timerId: number | undefined;
  } {
    return {
      color: undefined,
      showed: false,
      text: '',
      timerId: undefined,
    };
  },

  computed: {
    ...UICommonMapper.mapState(['message']),

    messageCloseWaitTimeMillisecond(): number {
      return settings.messageCloseWaitTime * 1000;
    },
  },

  watch: {
    async message() {
      const self = this;

      if (self.showed) {
        self.showed = false;

        if (!self._.isUndefined(self.timerId)) {
          clearTimeout(self.timerId);
          self.timerId = undefined;
        }

        await self.$nextTick();
      }

      self.color = self.message.color;
      self.text = self.message.text;

      self.showed = true;

      self.timerId = window.setTimeout(() => {
        self.showed = false;
      }, self.messageCloseWaitTimeMillisecond);
    },

    showed() {
      const self = this;

      if (!self.showed && !self._.isUndefined(self.timerId)) {
        clearTimeout(self.timerId);
        self.timerId = undefined;
      }
    },
  },
});
