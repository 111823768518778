import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';

export default {
  async getIvrFlows(params: { fields: string[] }) {
    try {
      const response = await Service.post(`/get-ivrflows`, JSON.stringify(params), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('getIvrFlows#Response', response);

      return response.data.result.ivrflows;
    } catch (error) {
      Log.error('getIvrFlows#Error', error);

      throw error;
    }
  },
};
