import { mdiAccount, mdiHome, mdiLock, mdiCreditCardSearch, mdiViewList } from '@mdi/js';
import dayjs from 'dayjs';
import _ from 'lodash';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { PARTNER_STATUSES, MESSAGE_ACCOUNT_DISABLED } from '@/resources/defines';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';
import { DomainPartner } from '@/store/modules/domain/partner';
import { DomainStrapiInfo } from '@/store/modules/domainStrapi/info';
import { UICommon } from '@/store/modules/ui/common';
import { UIPasswordReset } from '@/store/modules/ui/passwordReset';

const AuthService = ServiceFactory.get('auth');
const PartnerService = ServiceFactory.get('partner');

// Containers
const TheContainerDefault = () => import('@/containers/TheContainerDefault.vue');
const TheContainerSimple = () => import('@/containers/TheContainerSimple.vue');

// Views
const SamplePage = () => import('@/views/dev/SamplePage.vue');
const MainPage = () => import('@/views/MainPage.vue');
const ProfilePage = () => import('@/views/ProfilePage.vue');
const LoginPage = () => import('@/views/LoginPage.vue');
const PasswordResetReceptionPage = () => import('@/views/PasswordResetReceptionPage.vue');
const PasswordResetPage = () => import('@/views/PasswordResetPage.vue');
const PasswordPage = () => import('@/views/PasswordPage.vue');
const StatementPage = () => import('@/views/StatementPage.vue');
const NotificationLogsPage = () => import('@/views/NotificationLogsPage.vue');

Vue.use(VueRouter);

// 重要な情報が先に来るようにする (ただし、すべての要素で順序を統一する)
/* eslint-disable vue/sort-keys */
const routes: Array<RouteConfig> = [
  // ログイン前の画面
  {
    path: '/login',
    component: TheContainerSimple,
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginPage,
        meta: { title: 'ログイン' },
      },
    ],
  },
  {
    path: '/password_reset',
    component: TheContainerSimple,
    children: [
      {
        path: 'reception',
        name: 'PasswordResetReception',
        component: PasswordResetReceptionPage,
        meta: { title: 'パスワード再設定受付' },
      },
      {
        path: '',
        name: 'PasswordReset',
        component: PasswordResetPage,
        meta: { title: 'パスワード再設定' },
      },
    ],
  },
  // ログイン後の画面
  {
    path: '/',
    component: TheContainerDefault,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Main',
        component: MainPage,
        meta: { isMenuItem: true, icon: mdiHome, title: 'メイン' },
        props: (route) => ({ icon: route.meta.icon, title: route.meta.title }),
      },
      {
        path: 'notification_logs',
        name: 'NotificationLogs',
        component: NotificationLogsPage,
        meta: { isMenuItem: true, icon: mdiViewList, title: '通知履歴' },
        props: (route) => ({ icon: route.meta.icon, title: route.meta.title }),
      },
      {
        path: 'statement',
        name: 'Statement',
        component: StatementPage,
        meta: { isMenuItem: true, icon: mdiCreditCardSearch, title: 'ご利用明細' },
        props: (route) => ({ icon: route.meta.icon, title: route.meta.title }),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ProfilePage,
        meta: { icon: mdiAccount, title: 'プロファイル' },
        props: (route) => ({ icon: route.meta.icon, title: route.meta.title }),
      },
      {
        path: 'password',
        name: 'Password',
        component: PasswordPage,
        meta: { icon: mdiLock, title: 'パスワード変更' },
        props: (route) => ({ icon: route.meta.icon, title: route.meta.title }),
      },
    ],
  },
];
/* eslint-enable vue/sort-keys */

if (process.env.NODE_ENV === 'development') {
  // 重要な情報が先に来るようにする (ただし、すべての要素で順序を統一する)
  /* eslint-disable vue/sort-keys */
  routes.push({
    path: '/dev',
    component: TheContainerDefault,
    children: [
      {
        path: 'sample',
        name: 'Sample',
        component: SamplePage,
        meta: { isMenuItem: true, icon: mdiHome, title: '開発用サンプル' },
        props: (route) => ({ icon: route.meta.icon, title: route.meta.title }),
      },
    ],
  });
  /* eslint-enable vue/sort-keys */
}

routes.push({
  path: '/logout',
  redirect: '/login',
});

routes.push({
  path: '*',
  redirect: '/',
});

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

// 遷移処理中にローディング表示するためにフラグを立てる
router.beforeEach((to, from, next) => {
  const context = UICommon.context(store);

  context.actions.setNavigating({ navigating: true });
  next();
});

// ログイン画面遷移時に不要になった情報をクリアする
router.beforeEach((to, from, next) => {
  if (to.name === 'Login') {
    const domainAuthContext = DomainAuth.context(store);
    const domainPartnerContext = DomainPartner.context(store);
    const domainStrapiInfoContext = DomainStrapiInfo.context(store);

    domainAuthContext.actions.clearToken();
    domainAuthContext.actions.clearUserAttributes();
    domainPartnerContext.actions.clearPartnerInfo();
    domainStrapiInfoContext.actions.clearInfos();
  }

  next();
});

// 認証状態によるナビゲーションガード
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const domainAuthContext = DomainAuth.context(store);
    const isAuthenticated = domainAuthContext.getters.isAuthenticated(dayjs());

    if (isAuthenticated) {
      next();
    } else if (from.name === 'Login') {
      // ログイン画面からの戻るボタンで画面遷移処理中のままにならないよう防止
      const context = UICommon.context(store);
      context.actions.setNavigating({ navigating: false });
      next(false);
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

// 事業者ステータスによるナビゲーションガード
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const uiCommonContext = UICommon.context(store);
    const domainPartnerContext = DomainPartner.context(store);
    const { status } = domainPartnerContext.state;
    const enabledStatuses = _.filter(PARTNER_STATUSES, ['accountEnabled', true]);
    const enabledValues = _.mapValues(enabledStatuses, 'value');

    let enabledPartner = false;

    try {
      if (status === undefined) {
        const response = await PartnerService.getPartner({
          // ES設計のtypoによるものなので除外
          // cspell: disable-next-line
          fields: ['Status', 'dateRegisterd'],
        });

        // 成功時、取得した事業者ステータスをメモリ上に保存する
        domainPartnerContext.actions.setPartnerInfo({
          // ES設計のtypoによるものなので除外
          // cspell: disable-next-line
          registered: response.dateRegisterd,
          status: response.Status,
        });

        enabledPartner = _.includes(enabledValues, response.Status);
      } else {
        enabledPartner = _.includes(enabledValues, status);
      }

      if (enabledPartner) {
        next();
      } else {
        uiCommonContext.actions.setMessage({ color: 'error', text: MESSAGE_ACCOUNT_DISABLED });
        if (from.name === 'Login') {
          uiCommonContext.actions.setNavigating({ navigating: false });
          next(false);
        } else {
          next('/login');
        }
      }
    } catch (error) {
      Log.error(error);
      uiCommonContext.actions.setNavigating({ navigating: false });
      uiCommonContext.actions.setErrorMessage({ text: error.message });
      next(false);
    }
  } else {
    next();
  }
});

// パスワード再設定受付メールアドレスによるナビゲーションガード
router.beforeEach((to, from, next) => {
  if (to.name === 'PasswordReset') {
    const uiPasswordResetContext = UIPasswordReset.context(store);
    const { passwordResetInProcess } = uiPasswordResetContext.getters;

    if (passwordResetInProcess()) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

// 認証が必要な画面へのナビゲーションが確定後にトークンリフレッシュを行う
router.afterEach(async (to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      await AuthService.tokenRefresh();
    } catch (error) {
      Log.error(error);
      const context = UICommon.context(store);
      context.actions.setErrorMessage({ text: error.message });
    }
  }
});

// ナビゲーションが確定後にドキュメントタイトルを変更する
router.afterEach((to) => {
  const { title } = to.meta;

  if (_.isEmpty(settings.deployTargetInPageTitle)) {
    if (_.isEmpty(title)) {
      document.title = 'CallReach';
    } else {
      document.title = `${title} | CallReach`;
    }
  } else if (_.isEmpty(title)) {
    document.title = `${settings.deployTargetInPageTitle} | CallReach`;
  } else {
    document.title = `${settings.deployTargetInPageTitle} | ${title} | CallReach`;
  }
});

// 遷移処理中のローディング表示を終了するためにフラグを消す
router.afterEach(() => {
  const context = UICommon.context(store);

  context.actions.setNavigating({ navigating: false });
});

export default router;
