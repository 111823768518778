import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// メッセージ
type Message = {
  // メッセージ背景色
  color: string;
  // メッセージテキスト
  text: string;
};

// メッセージの初期値
const initialMessage: Readonly<Message> = Object.freeze({
  color: '',
  text: '',
});

class UICommonState {
  // メッセージ
  message: Message = _.cloneDeep(initialMessage);

  // 画面遷移処理中か
  navigating = false;

  // ナビゲーションメニューを表示しているかどうか
  showedNavigationMenu = false;
}

class UICommonGetters extends Getters<UICommonState> {}

class UICommonMutations extends Mutations<UICommonState> {
  clearMessage() {
    this.state.message = _.cloneDeep(initialMessage);
  }

  setMessage(payload: Message) {
    this.state.message = payload;
  }

  setNavigating(payload: { navigating: boolean }) {
    this.state.navigating = payload.navigating;
  }

  setShowedNavigationMenu(payload: { showed: boolean }) {
    this.state.showedNavigationMenu = payload.showed;
  }
}

class UICommonActions extends Actions<
  UICommonState,
  UICommonGetters,
  UICommonMutations,
  UICommonActions
> {
  // メッセージをクリアする
  clearMessage() {
    this.mutations.clearMessage();
  }

  // エラーメッセージを設定する (背景色はメッセージ内のエラーコードに合わせて設定する)
  setErrorMessage(payload: { text: string }) {
    const codeRegExp = /^.* \[([A-Z][^-]+)-([^-])-([0-9]{2,5})-([0-9]{4,8})\]$/i;
    const matched = codeRegExp.exec(payload.text);
    let color = 'error';

    if (_.isArray(matched)) {
      switch (matched[2].toUpperCase()) {
        case 'C':
        case 'D':
        case 'I':
          color = 'info';
          break;
        case 'W':
          color = 'warning';
          break;
        default:
          color = 'error';
          break;
      }
    }

    this.mutations.setMessage({ color, text: payload.text });
  }

  // メッセージを設定する
  setMessage(payload: Message) {
    this.mutations.setMessage(payload);
  }

  // 画面遷移処理中かどうかを設定する
  setNavigating(payload: { navigating: boolean }) {
    this.mutations.setNavigating(payload);
  }

  // ナビゲーションメニュー表示有無を設定する
  setShowedNavigationMenu(payload: { showed: boolean }) {
    this.mutations.setShowedNavigationMenu(payload);
  }

  // ナビゲーションメニュー表示有無を切り替える
  toggleShowedNavigationMenu() {
    this.mutations.setShowedNavigationMenu({ showed: !this.state.showedNavigationMenu });
  }
}

export const UICommon = new Module({
  actions: UICommonActions,
  getters: UICommonGetters,
  mutations: UICommonMutations,
  state: UICommonState,
});

export const UICommonMapper = createMapper(UICommon);
