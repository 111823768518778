import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';

// トークン
type Token = {
  // アクセストークン。ユーザー情報を更新するときに使用
  access_token: string;
  // IDトークン。Cognitoトークンを必要とする他REST APIを実行するときに使用
  id_token: string;
  // リフレッシュトークン。アクセス/IDトークンを更新するときに使用
  refresh_token: string;
};

// ユーザー属性
type UserAttributes = {
  // user_attributesで、Name:'email'の値
  email: string;
  // user_attributesで、Name:'family_name'の値
  family_name: string;
  // user_attributesで、Name:'given_name'の値
  given_name: string;
  // user_attributesで、Name:'custom:partner_id'の値
  partner_id: string;
  // user_attributesで、Name:'custom:family_name_kana'の値
  family_name_kana: string;
  // user_attributesで、Name:'custom:given_name_kana'の値
  given_name_kana: string;
  // user_attributesで、Name:'custom:company_name'の値
  company_name: string;
};

// ユーザー属性の一部
export type PartialUserAttributes = Partial<UserAttributes>;

// トークンの初期値
const initialToken: Readonly<Token> = Object.freeze({
  // APIによりパラメータ名が定義されているため除外
  /* eslint-disable @typescript-eslint/camelcase */
  access_token: '',
  id_token: '',
  refresh_token: '',
  /* eslint-enable @typescript-eslint/camelcase */
});

// ユーザー属性の初期値
const initialUserAttributes: Readonly<UserAttributes> = Object.freeze({
  // APIにより属性名が定義されているため除外
  /* eslint-disable @typescript-eslint/camelcase */
  company_name: '',
  email: '',
  family_name: '',
  family_name_kana: '',
  given_name: '',
  given_name_kana: '',
  partner_id: '',
  /* eslint-enable @typescript-eslint/camelcase */
});

class DomainAuthState {
  // 有効期限
  expireDate: dayjs.Dayjs | undefined = undefined;

  // トークン
  token: Token = _.cloneDeep(initialToken);

  // ユーザー情報
  userAttributes: UserAttributes = _.cloneDeep(initialUserAttributes);
}

class DomainAuthGetters extends Getters<DomainAuthState> {
  isAuthenticated(now: dayjs.Dayjs) {
    if (this.state.expireDate === undefined) {
      return false;
    }
    if (!dayjs.isDayjs(now)) {
      return false;
    }

    dayjs.extend(isSameOrAfter);
    return this.state.expireDate.isSameOrAfter(now);
  }
}

class DomainAuthMutations extends Mutations<DomainAuthState> {
  clearExpireDate() {
    this.state.expireDate = undefined;
  }

  clearToken() {
    this.state.token = _.cloneDeep(initialToken);
  }

  clearUserAttributes() {
    this.state.userAttributes = _.cloneDeep(initialUserAttributes);
  }

  setExpireDate(payload: { date: dayjs.Dayjs }) {
    this.state.expireDate = payload.date;
  }

  setToken(payload: Token) {
    this.state.token = payload;
  }

  setUserAttributes(payload: UserAttributes) {
    this.state.userAttributes = payload;
  }
}

class DomainAuthActions extends Actions<
  DomainAuthState,
  DomainAuthGetters,
  DomainAuthMutations,
  DomainAuthActions
> {
  // トークンをクリアする
  clearToken() {
    this.mutations.clearToken();
    this.mutations.clearExpireDate();
  }

  // ユーザー属性情報をクリアする
  clearUserAttributes() {
    this.mutations.clearUserAttributes();
  }

  // 認証情報をセッションストレージから復元する
  restoreAuth() {
    const storageItem = sessionStorage.getItem('crAuth');

    if (_.isString(storageItem)) {
      const storageItemJSON = JSON.parse(storageItem);

      const tokenInfo = {
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        access_token: _.get(storageItemJSON, 'domain.auth.token.access_token'),
        id_token: _.get(storageItemJSON, 'domain.auth.token.id_token'),
        refresh_token: _.get(storageItemJSON, 'domain.auth.token.refresh_token'),
        /* eslint-enable @typescript-eslint/camelcase */
      };

      this.mutations.setToken(tokenInfo);

      // 有効期限をセット
      const decoded = jwt.decode(tokenInfo.access_token, { complete: true });
      const expMsec = _.get(decoded, 'payload.exp', 0);
      const expire = dayjs(expMsec * 1000);
      this.mutations.setExpireDate({ date: expire });
    }
  }

  // トークンを設定する(トークンリフレッシュ後もこちらで)
  setToken(payload: Token) {
    this.mutations.setToken(payload);

    // 有効期限をセット
    const decoded = jwt.decode(payload.access_token, { complete: true });
    const expMsec = _.get(decoded, 'payload.exp', 0);
    const expire = dayjs(expMsec * 1000);
    this.mutations.setExpireDate({ date: expire });
  }

  // ユーザー情報をセット
  setUserAttributes(payload: UserAttributes) {
    this.mutations.setUserAttributes(payload);
  }

  // 特定の属性情報のみをセット
  setUserAttributesSpecified(payload: PartialUserAttributes) {
    const newUserAttributes = _.assign({}, this.state.userAttributes, payload);

    this.mutations.setUserAttributes(newUserAttributes);
  }
}

export const DomainAuth = new Module({
  actions: DomainAuthActions,
  getters: DomainAuthGetters,
  mutations: DomainAuthMutations,
  state: DomainAuthState,
});

export const DomainAuthMapper = createMapper(DomainAuth);
