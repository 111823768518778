import dayjs from 'dayjs';
import lodash from 'lodash';
import Vue from 'vue';
import VueLodash from 'vue-lodash';
import Vuelidate from 'vuelidate';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import DayjsWrapper from '@/resources/plugins/DayjsWrapper/DayjsWrapper';
import Logger from '@/resources/plugins/Logger/Logger';
import VuelidateExtension from '@/resources/plugins/VuelidateExtension/VuelidateExtension';
import router from '@/router';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';

Vue.use(VueLodash, { lodash });
Vue.use(Vuelidate);
Vue.use(Logger);
Vue.use(VuelidateExtension);
Vue.use(DayjsWrapper, { dayjs });

const requireComponents = require.context('@/components/generic/', true, /Base[A-Z]\w+\.vue$/);

// 基底コンポーネントをグローバル登録
requireComponents.keys().forEach((fileName) => {
  let baseComponentConfig = requireComponents(fileName);
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig;

  const baseComponentName = Vue._.upperFirst(
    baseComponentConfig.extendOptions?.name ||
      Vue._.camelCase(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))
  );

  Vue.component(baseComponentName, baseComponentConfig);
});

Vue.config.productionTip = false;

// vuex-persistedstateのストア復元タイミングではナビゲーションガードに間に合わないため、ここで復元する
const context = DomainAuth.context(store);
context.actions.restoreAuth();

new Vue({
  name: 'Root',
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
