import generateHeaders from '@/resources/functions/generateHeaders';
import Log from '@/resources/plugins/Logger/Log';
import Service from '@/services/ui/Service';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';

export default {
  async getUserAttributes() {
    try {
      const response = await Service.get(`/user-attributes`, {
        headers: generateHeaders('accessAndIdToken', { Accept: 'application/json' }),
      });

      Log.debug('getUserAttributes#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('getUserAttributes#Error', error);

      throw error;
    }
  },

  async login(params: { email: string; password: string }) {
    try {
      const response = await Service.post(`/auth-token`, JSON.stringify(params), {
        headers: generateHeaders('apiKey', { Accept: 'application/json' }),
      });

      Log.debug('login#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('login#Error', error);

      throw error;
    }
  },

  async logout() {
    try {
      const response = await Service.post(`/reset-auth-token`, JSON.stringify({}), {
        headers: generateHeaders('accessAndIdToken', { Accept: 'application/json' }),
      });

      Log.debug('logout#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('logout#Error', error);

      throw error;
    }
  },

  async requestPasswordRest(params: { email: string }) {
    try {
      const response = await Service.post(`/forgot-password`, JSON.stringify(params), {
        headers: generateHeaders('apiKey', { Accept: 'application/json' }),
      });

      Log.debug('requestPasswordRest#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('requestPasswordRest#Error', error);

      throw error;
    }
  },

  async resetPassword(params: { email: string; confirmation_code: string; new_password: string }) {
    try {
      const response = await Service.post(`/new-password`, JSON.stringify(params), {
        headers: generateHeaders('apiKey', { Accept: 'application/json' }),
      });

      Log.debug('resetPassword#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('resetPassword#Error', error);

      throw error;
    }
  },

  async tokenRefresh() {
    try {
      const context = DomainAuth.context(store);
      // APIによりパラメータ名が定義されているため除外
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      const { refresh_token } = context.state.token;

      // APIによりパラメータ名が定義されているため除外
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      const response = await Service.put(`/auth-token`, JSON.stringify({ refresh_token }), {
        headers: generateHeaders('idToken', { Accept: 'application/json' }),
      });

      Log.debug('tokenRefresh#Response', response);

      // トークンをストア
      context.actions.setToken({
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        access_token: response.data.result.access_token,
        id_token: response.data.result.id_token,
        refresh_token: response.data.result.refresh_token,
        /* eslint-enable @typescript-eslint/camelcase */
      });

      return response.data.result;
    } catch (error) {
      Log.error('tokenRefresh#Error', error);

      throw error;
    }
  },

  async updateEmail(params: { new_email: string }) {
    try {
      const response = await Service.put(
        `/email`,
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        JSON.stringify(params),
        {
          headers: generateHeaders('accessAndIdToken', { Accept: 'application/json' }),
        }
      );

      Log.debug('updateEmail#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('updateEmail#Error', error);

      throw error;
    }
  },

  async updatePassword(params: { previous_password: string; proposed_password: string }) {
    try {
      const response = await Service.put(`/password`, JSON.stringify(params), {
        headers: generateHeaders('accessAndIdToken', { Accept: 'application/json' }),
      });

      Log.debug('updatePassword#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('updatePassword#Error', error);

      throw error;
    }
  },

  async updateUserAttributes(params: {
    user_attributes: {
      family_name?: string;
      given_name?: string;
      family_name_kana?: string;
      given_name_kana?: string;
      company_name?: string;
    };
  }) {
    try {
      const response = await Service.put(
        `/user-attributes`,
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        JSON.stringify(params),
        {
          headers: generateHeaders('accessAndIdToken', { Accept: 'application/json' }),
        }
      );

      Log.debug('updateUserAttributes#Response', response);

      return response.data.result;
    } catch (error) {
      Log.error('updateUserAttributes#Error', error);

      throw error;
    }
  },
};
