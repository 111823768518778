import _ from 'lodash';
import settings from '@/settings';
import store from '@/store';
import { DomainAuth } from '@/store/modules/domain/auth';

const generateHeaders = (
  authType: 'apiKey' | 'idToken' | 'accessAndIdToken',
  // ヘッダーの値は不定のため除外
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: { [key: string]: any }
) => {
  if (authType === 'apiKey') {
    return _.assignIn(headers, {
      'x-api-key': settings.apiKey,
    });
  }

  if (authType === 'idToken') {
    const context = DomainAuth.context(store);

    // APIによりパラメータ名が定義されているため除外
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { id_token } = context.state.token;

    return _.assignIn(headers, {
      // APIによりパラメータ名が定義されているため除外
      // eslint-disable-next-line @typescript-eslint/camelcase
      Authorization: id_token,
    });
  }

  const context = DomainAuth.context(store);

  // APIによりパラメータ名が定義されているため除外
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { access_token, id_token } = context.state.token;

  return _.assignIn(headers, {
    // APIによりパラメータ名が定義されているため除外
    /* eslint-disable @typescript-eslint/camelcase */
    Authorization: id_token,
    access_token,
    /* eslint-enable @typescript-eslint/camelcase */
  });
};

export default generateHeaders;
