import AuthService from '@/services/ui/AuthService';
import DialService from '@/services/ui/DialService';
import InvoiceService from '@/services/ui/InvoiceService';
import IvrFlowService from '@/services/ui/IvrFlowService';
import LoggerService from '@/services/ui/LoggerService';
import PartnerService from '@/services/ui/PartnerService';
import NotificationLogService from './NotificationLog';

const services = {
  auth: AuthService,
  dial: DialService,
  invoice: InvoiceService,
  ivrFlow: IvrFlowService,
  logger: LoggerService,
  notificationLog: NotificationLogService,
  partner: PartnerService,
};

export default {
  get<K extends keyof typeof services>(name: K): typeof services[K] {
    return services[name];
  },
};
