import settingsDevelopment from '@/settings/settingsDevelopment';
import settingsProduction from '@/settings/settingsProduction';
import settingsStaging from '@/settings/settingsStaging';

export type CallReachSettings = {
  messageCloseWaitTime: number;
  apiEndpoint: string;
  apiKey: string;
  deployTargetInPageTitle: string;
  deployTargetName: string;
  helpButtonItems: {
    [pageId: string]: {
      icon: string;
      title: string;
      url: string;
    }[];
  };
  externalLinkURLs: {
    loginIssue: string;
  };
  interfaceAPIEndpoint: string;
  strapiAPIEndpoint: string;
  strapiImageBaseURL: string;
};

let settings: CallReachSettings;

if (process.env.VUE_APP_SETTINGS_TYPE === 'development') {
  settings = settingsDevelopment;
} else if (process.env.VUE_APP_SETTINGS_TYPE === 'staging') {
  settings = settingsStaging;
} else {
  settings = settingsProduction;
}

export default Object.freeze(settings);
