import { mdiBookOpenVariant, mdiFaceAgent } from '@mdi/js';
import type { CallReachSettings } from '@/settings/index';

// 設計書記載の順序で定義する
/* eslint-disable vue/sort-keys */
const settingsDevelopment: CallReachSettings = {
  messageCloseWaitTime: 15,
  apiEndpoint: 'https://devuiapi.callreach.jp',
  apiKey: 'aQb54fzjub9odhtOzURwg1miJ65FtrID8IxR0aii',
  deployTargetInPageTitle: 'DEV',
  deployTargetName: '開発環境',
  helpButtonItems: {
    Main: [
      {
        icon: mdiBookOpenVariant,
        title: 'マニュアル',
        url: 'https://manual.callreach.jp/?p=219',
      },
      {
        icon: mdiFaceAgent,
        title: 'お問い合わせ',
        url: 'https://forms.gle/RjBNhVvixiLbCWRDA',
      },
    ],
    NotificationLogs: [
      {
        icon: mdiBookOpenVariant,
        title: 'マニュアル',
        url: 'https://manual.callreach.jp/?p=410',
      },
      {
        icon: mdiFaceAgent,
        title: 'お問い合わせ',
        url: 'https://forms.gle/RjBNhVvixiLbCWRDA',
      },
    ],
    Statement: [
      {
        icon: mdiBookOpenVariant,
        title: 'マニュアル',
        url: 'https://manual.callreach.jp/?p=511',
      },
      {
        icon: mdiFaceAgent,
        title: 'お問い合わせ',
        url: 'https://forms.gle/RjBNhVvixiLbCWRDA',
      },
    ],
    Profile: [
      {
        icon: mdiBookOpenVariant,
        title: 'マニュアル',
        url: 'https://manual.callreach.jp/?cat=8',
      },
      {
        icon: mdiFaceAgent,
        title: 'お問い合わせ',
        url: 'https://forms.gle/RjBNhVvixiLbCWRDA',
      },
    ],
    Password: [
      {
        icon: mdiBookOpenVariant,
        title: 'マニュアル',
        url: 'https://manual.callreach.jp/?p=311',
      },
      {
        icon: mdiFaceAgent,
        title: '問い合わせ',
        url: 'https://forms.gle/RjBNhVvixiLbCWRDA',
      },
    ],
  },
  externalLinkURLs: {
    loginIssue: 'https://forms.gle/RjBNhVvixiLbCWRDA',
  },
  interfaceAPIEndpoint: 'https://devapi.callreach.jp/wd/3.0/',
  strapiAPIEndpoint: 'https://devuiapi.callreach.jp/strapi/info/',
  strapiImageBaseURL: 'https://devuiapi.callreach.jp/strapi/image/',
};
/* eslint-enable vue/sort-keys */

export default settingsDevelopment;
